h1,h2,h3,h4,h5,p{
  font-family: Arial Black,Arial, Helvetica, sans-serif !important;
}
.button-layout{
  font-size:3rem;
}

.img-fluid{
  cursor:pointer;
}

.hover{
  transition:all .3s ease;
}
.hover:hover{
   transform:scale(1.07)
}

.carousel-img {
  width: 100%;
  height: 75vh;
  overflow: hidden !important;
  object-fit: cover;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.carousel-img-sm {
  width: 95vw;
  height: auto;
  overflow: hidden !important;
  object-fit: contain;
}

.hero {
  margin-top: 6rem;
  font-size: xx-large;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(65, 65, 65, 0.6);
  z-index: 9999;
}

.hero h1 {
  font-size: 4rem;
}

.bg-opace {
  background: rgba(122, 122, 122, 0.5);
}

.nav-link {
  font-size: 1.3rem;
  margin: 0px 10px;
  border: none !important;
}

.nav-link:hover {
  background: #606163 !important;
}

.object-contain{
  object-fit: contain !important;
}

.jumbotron {
  border: transparent;
  border-radius: 7px;
  overflow: hidden;
  box-shadow: rgba(26, 26, 26, 0.5) 8px 8px 10px 1px !important;
}

.carousel-item {
  overflow: hidden;
}

.icons {
  fill: #f9a916;
  width: 100px;
  height: 100px;
}

.btn-outline-warning {
  border: 1px solid #f99b16;
}

.btn-outline-warning:hover {
  border: 1px solid transparent;
}

.bg-warning {
  background: #f9a916;
}

.btn-dark {
  background: #3a3f44;
}

.btn-dark:hover {
  background: #535558 !important;
}

.btn-outline-dark {
  border: 1px solid #3a3f44;
}

.btn-outline-dark:hover {
  border: 1px solid transparent;
}

/* up to top button */

.top-link.show {
  visibility: visible;
  opacity: 1;
}

.top-link.hide {
  visibility: hidden;
  opacity: 0;
}

.top-link svg {
  fill: #000;
  width: 24px;
  height: 12px;
}

.top-link:hover {
  background-color: #e8e8e8;
}

.top-link:hover svg {
  fill: #000000;
}
/* onhover inmage effect */

#photos img {
  /* Just in case there are inline attributes */
  width: 50% !important;
  height: 50% !important;
}

a.project {
  display: block;
  background: black;
  padding: 0;
  position: relative;
  margin: 1rem;
}

a.project img {
  display: block;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

a.project:hover:before {
  content: '';
  display: block;
  position: absolute;
  width: 90%;
  height: 3px;
  bottom: 20px;
  right: 20px;
  background: white;
}

a.project:hover:after {
  content: '';
  display: block;
  position: absolute;
  height: 85%;
  width: 3px;
  bottom: 20px;
  right: 20px;
  background: white;
}

a.project:hover img {
  opacity: 0.3;
}

a.project span {
  text-align: center;
  visibility: hidden;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  line-height: 100%;
}

a.project:hover span {
  color: #fff;
  visibility: visible;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.card img {
  width: 320px;
  height: 240px;
}

.img-container,
.card-img-top {
  object-fit: fill;
  overflow: hidden !important;
}

.card-img-top img {
  width: 85%;
  height: 85%;
}

.img-nosotros {
  overflow: hidden;
  border-radius: 0px 0px 100px 0px;
}


.card {
  border: 1px solid transparent;
  box-shadow: rgba(26, 26, 26, 0.5) -3px 5px 10px 1px !important;
}

.h-25 {
  height: 25% !important;
}

.product-card {
  margin: 0.6rem;
  background: #25272B;
  overflow: hidden;
}



.product-card-img img {
  margin-left: 1rem;
  object-fit: contain !important;
  height: 14rem !important;
}

.product-card {
  max-height: 16rem;
  transition: all .3s ease
}

.product-card:hover {
  transform: scale(1.09);
  z-index: 99999;
}

.btn-dark:active {
  background: none !important;

}

.nav-link:focus {
  background: none !important;
}

.dropdown-menu {
  z-index: 9999;
}

.upper{
  z-index: 99999 !important;
}
